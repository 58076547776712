var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-avatar--wrapper rounded",
      style: [_vm.style, _vm.customStyle],
      attrs: { "aria-hidden": "true" }
    },
    [
      this.isImage
        ? _c("img", {
            staticStyle: { display: "none" },
            attrs: { src: this.src },
            on: { error: _vm.onImgError }
          })
        : _vm._e(),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !this.isImage,
              expression: "!this.isImage"
            }
          ]
        },
        [_vm._v(_vm._s(_vm.userInitial))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }