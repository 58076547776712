<template>
  <card-layout :cover="loading">
    <template #header>
      <span class="float-left">
        <CAvatar :initials="branchID" :username="branchName" />
      </span>
      <span class="ml-2 text-center">
        <span class="header-text float-center p-4">{{ branchName }}</span></span>
    </template>
    <slot name="before-table"></slot>
    <CRow>
      <CCol md="6" sm="10">
        <CInput placeholder="Search for truck" :value.sync="searchValue" :lazy="true">
          <template #append>
            <CButton color="info">
              <CIcon name="cil-magnifying-glass" /> Search
            </CButton>
          </template>
        </CInput>
      </CCol>
      <CCol class="text-right">
        <CButton color="info" @click="handleRefreshClick">
          <CIcon name="cil-sync"></CIcon>
        </CButton>
      </CCol>
    </CRow>
    <CRow v-if="false">
      <CCol>
        <CRelativeTimePicker :options="timePicker.relativeTimes" :value.sync="timePicker.relativeTimeSelected">
        </CRelativeTimePicker>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <span class="time-label"><h6>Showing the Last {{ timeRange }}</h6></span>
      </CCol>
      <CCol>
        <span class="float-right text-middle"> <CInputCheckbox 
        shape="pill"
        variant="outline" 
        color="info"
        type="checkbox"
        label="Hide Offline Devices"

        :checked.sync="filter.showAll"
        >
        <template #label>
          <h6>
          Only Show Online & Alarming
        </h6>
          </template>
          </CInputCheckbox> </span>

      </CCol>
    </CRow>
    <CRow>
      <!--CCol v-show="false" col="12" v-for="item of sortedStatus" :key="item.id">
        <template #header>
          <span class="float-left">
            <CAvatar initials="LLE" username="Edmonton" />
          </span>
          <span class="ml-2 text-center"> <h4 class="">Edmonton</h4></span>
        </template>
      </!--CCol-->
      <CCol>

        <CDataTable :fields="fields" :items="filteredStatus" class="my-2 py-0 truck-status-table" :table-filter="false"
          :table-filter-value="searchValue" hover :items-per-page="small ? 20 : 50" pagination dense
          :row-clicked="handleRowClicked" responsive 
          :noItemsView="{
            noResults: 'No results Available',
            noItems: 'No Devices are Online or Alarming',
          }">
          <template #id> </template>
          <template #status> </template>
          <template #name> </template>
          <template #device> </template>
          <template #id> </template>
          <template #status> </template>

          <template #details="{ item }">
            <div class="truck-status-container rounded">
              <TruckStatusCard class="p-0 m-0" @click="handleRowClicked(item, item.id)" :item="item"
                :points="item.points" :key="statusUpdate">
              </TruckStatusCard>
            </div>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
  </card-layout>
</template>

<style scoped>
.header-text {
  font-size: 16px;
}

.truck-status-table{
  overflow: hidden;
  overflow-y: hidden;
}

.truck-status-container {
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 1px;
  padding-right: 2px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  overflow-y: hidden;
}

.icon-container {
  margin: auto;
  padding: 1rem;
  max-height: 20px;
}

.icon__style {
  max-height: 50px;
  min-height: 20px;
}

.status-badge-container {
  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  padding: 1px;
  padding-top: 3px;
}

.status-badge {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  min-width: 100px;
}
</style>

<script>
import CardLayout from "@/layouts/CardLayout.vue";
import CCallout from "@/components/base/CCallout.vue"; 
import CAvatar from "@/components/base/CAvatar.vue";
import moment from "moment";
import TruckStatusCard from "./TruckStatusCard.vue";
import statusHelper from "@/lib/hydrovac_lib/statusHelper";
import { rangeMS } from "@/data/appData";
import CRelativeTimePicker from "@/components/time/CRelativeTimePicker.vue";
import timeConstants from "@/components/time/constants.js";
import CLabel from "@/components/base/CLabel.vue";



const { MS_PER_HOUR, MS_PER_MINUTE, MS_PER_DAY } = timeConstants;
const UPDATE_PERIOD = 30000;

const { HydrovacStatus, alarmingEnum } = statusHelper

 
const defaultFields = [
  {
    key: "id",
    label: "",
    _classes: '[""]',
  },
  { key: "name", label: "" },
  { key: "device", label: "" },
  { key: "status", label: "" },
];
const defaultTimes =
  [
    //{id:"5m", value:"5m", label:"Last 5 Minutes"},
    //{id:"10m",value:"10m", label:"Last 10 Minutes"},
    //{ id: "30m", value: "30m", label: "Show Last 30 Minutes", range:30*MS_PER_MINUTE },
    { id: "1h", value: "1h", label: "Show Last 1 Hour", range: 1 * MS_PER_HOUR },
    { id: "4h", value: "4h", label: "Show Last 4 Hours", range: 4 * MS_PER_HOUR },
    { id: "8h", value: "8h", label: "Show Last 8 Hours", range: 8 * MS_PER_HOUR },
    { id: "24h", value: "24h", label: "Show Last 24 Hours", range: 1 * MS_PER_DAY },

  ]




export default {
  name: "TruckStatusControl",
  components: {
    CardLayout,
    CAvatar,
    TruckStatusCard,
    CRelativeTimePicker,
    CCallout,
    CLabel
},
  props: {
    devices: {
      type: Array,
    },
    branchName: {
      type: String,
    },
    branchID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      small: true,
      loading: false,
      fields: defaultFields,
      updatePeriod: UPDATE_PERIOD,
      statusUpdate: 1,
      filter: {
        showAll: true, 
      }, 
      items: [],
      //items: defaultItems,
      realtimeData: {},
      searchValue: "",
      rangeMS: rangeMS ? rangeMS : 1 * MS_PER_HOUR, // Default is last hour minutes
      timePicker: {
        slectedStart: "now-8h",
        selectedEnd: "now", // Always now 
        selectedRangeID: "8h",
        selectedRange: undefined,
        relativeTimes: defaultTimes,
      }

    };
  },
  computed: {
    sortedStatus() {
      let sorted = this.sortByStatus(this.items);
      return sorted;
    },
    selectedBranch: {
      get() {
        return this.$store.getters.selectedBranch;
      }
    },
    filteredStatus(){
        // TODO: What do we filter by here 
        console.log(this.items); 
        if(!this.filter.showAll){
          return this.items; 
        }
        else{
          return this.items.filter(item=>item.status==="alarming" || item.connected === "online");
        }
        

    }, 
    from() {
      return this.timerPicker.slectedStart;
    },
    to() {
      return this.timerPicker.selectedEnd;
    },
    relativeTimeSelected: {
      get() {
        let temp = this.timerPicker.selectedRange;
        if (temp)
          return temp.value;
        return this.timerPicker.relativeTimes[0].value;

      },
      set(value) {
        this.timerPicker.selectedRangeID = value;
        let temp = this.timerPicker.relativeTimes.find(x => x.id === this.timerPicker.selectedRangeID);
        this.timerPicker.selectedRange = temp;
        if (temp)
          this.rangeMS = temp.range;

        this.timerPicker.slectedStart = "now-" + value;
      }


    },
    timeRange() {
      let time = this.rangeMS / MS_PER_HOUR;
      let unit = "hours"
      let temp = `${time} ${unit}`;
      return temp;
    }

  },

  watch: {
    selectedBranch(oldVal, newVal) {
      if (oldVal != newVal) {
        // Clear the current 
        this.clear();
        this.handleRefreshClick();
      }
    },

  },
  methods: {
    sortByOnlineState(items) {
      items.sort((a, b) => {
        if (!a.deviceInfo || !b.deviceInfo) {
          return 0;
        }
        let aVal = a.connected === "online" ? 1 : 0;
        let bVal = b.connected === "online" ? 1 : 0;
        return (bVal - aVal);
      })
      
    },
    getOnlineState(item, threshold = 3600 * 1000) {
      let temp = "offline";
      if (item.deviceInfo.status && item.deviceInfo.status.last_connect) {
        let timestamp = item.deviceInfo.status.last_connect;
        let datetime = new Date(timestamp);

        // If Last connected within x hours then treat as online 
        if ((Date.now() - datetime) < threshold) {
          // Update 
          temp = "online";
        }
      }
      else if (item.deviceInfo.lastConnect) {
        temp = "online";
      }
      return temp;
    },
    sortByStatus(items) {

      items.sort((a, b) => {
        let aVal = alarmingEnum[a.status];
        let bVal = alarmingEnum[b.status];
        if (!aVal && !bVal) {
          return 0;
        } else if (aVal > bVal) {
          return -1;
        } else if (aVal < bVal) {
          return 1;
        } else return 0;
      });
      
    },

    getTimestamp(item) {
      let duration = 1000;
      let temp = moment.duration(duration, "ms").humanize();
      return temp;
    },
    async handleRefreshClick(e) {
      try {
        if(this.loading){
          return; 
        }
        this.loading = true;
        await this.update(e);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    handleRowClicked(item, index, name, e) {
      let row = index;
      let sender = e;
      this.$emit("row-clicked", item, row, name, sender);
    },

    getColor(item) {
      let color = "primary";
      switch (item.status) {
        case "alarming":
          color = "danger";
          break;
        case "warning":
          color = "warning";
          break;
        case "idle":
          color = "info";
          break;
        case "moving":
          color = "success";
          break;
        case "stopped":
          color = "secondary";
          break;
      }
      return color;
    },

    async getFleetStatus(params) {
      /** Load the Current Fleet Status, filtered by active devices */
      try {
        let fleet = await this.$app.getFleetStatus(params);

        return fleet;
      }
      catch (err) {
        console.log(err)
      }
    },
    async realtime(item, start, end) {
      try {
        // Realtime the device. 
      }
      catch (err) {
        console.log(err);
      }
    },
    clear() {
      this.items = [];
    },
    async doFirstPaint(){
      let devices = this.$app.myDevices; 
      try{
        // Should we hide units that are not active 
        devices = devices.filter(item=>item.status!="active");
        let tempCollection = []; 
          // Suspend layout 
          for(let device of devices){
            let myStatus = new HydrovacStatus(); 
            myStatus.status = "stopped"; // What is the default?  
            myStatus.deviceInfo = device;
            myStatus.name = device.name; // 
            myStatus.device = device.device; // Device ID 
            tempCollection.push(myStatus); 
          }


          this.items = tempCollection; 
        }
        catch(err){
          console.log(err); 
        }
    }, 
    async update(e) {
      try {
        let branch_id = this.$app.myBranchId;
        this.$alerting.rangeMS = this.rangeMS;

        // Do First Paint 
        if(!this.items || this.items.length===0){
          await this.doFirstPaint(); 
        }

        const params = {branch_id: branch_id}

        let fleetStatus = await this.$fleet.loadFleetStatus();

        console.log(fleetStatus); 

        const map = this.$fleet.mapFleetStatus(fleetStatus); 
        console.log(map); 
        

        let temp = await this.$alerting.update(branch_id);

        console.log(temp); 
        
        // For Each Item, update the status
        if(temp){
        for(let alert of temp){
           let status = map[alert.device]; 
           console.log(status);
           if(status){
            console.log(status); 
              alert.fleetStatus = status; 
           }
        }
      }




        this.sortByOnlineState(temp);
        this.sortByStatus(temp);

        this.items = temp; //this.$alerting.alertStatus; // Get the initial condition
        //this.items = temp;

      } catch (err) {
        console.log(err);
      }

      this.statusUpdate += 1; // Force Re-render 
    },
    handleElementUpdate(e) {
      console.log("Event Generatined");
    },
    async handleTimer() {
    if (this.loading)
      return
    this.update()
  },
  },




  async mounted() {
    try{
      await this.update();
    }
    catch(err){
      console.log(err); 
    }
    /*
    this.timerHandle = setInterval(() => {
      this.handleTimer() ;
    }, this.updatePeriod);
    */
  },
  destroyed() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }
  },
  async activated(){
    try{
      console.log("activated"); 
      await this.update();
    }
    catch(err){
      console.log(err); 
    }
    this.timerHandle = setInterval(() => {
      this.handleTimer() ;
    }, this.updatePeriod);
  },
  deactivated() {
    console.log("deactivated"); 

    clearInterval(this.timerHandle);

  },

};
</script>
