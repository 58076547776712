var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-layout",
    {
      attrs: { cover: _vm.loading },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "span",
                { staticClass: "float-left" },
                [
                  _c("CAvatar", {
                    attrs: { initials: _vm.branchID, username: _vm.branchName }
                  })
                ],
                1
              ),
              _c("span", { staticClass: "ml-2 text-center" }, [
                _c("span", { staticClass: "header-text float-center p-4" }, [
                  _vm._v(_vm._s(_vm.branchName))
                ])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._t("before-table"),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "6", sm: "10" } },
            [
              _c("CInput", {
                attrs: {
                  placeholder: "Search for truck",
                  value: _vm.searchValue,
                  lazy: true
                },
                on: {
                  "update:value": function($event) {
                    _vm.searchValue = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function() {
                      return [
                        _c(
                          "CButton",
                          { attrs: { color: "info" } },
                          [
                            _c("CIcon", {
                              attrs: { name: "cil-magnifying-glass" }
                            }),
                            _vm._v(" Search ")
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "CCol",
            { staticClass: "text-right" },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "info" },
                  on: { click: _vm.handleRefreshClick }
                },
                [_c("CIcon", { attrs: { name: "cil-sync" } })],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      false
        ? _c(
            "CRow",
            [
              _c(
                "CCol",
                [
                  _c("CRelativeTimePicker", {
                    attrs: {
                      options: _vm.timePicker.relativeTimes,
                      value: _vm.timePicker.relativeTimeSelected
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.timePicker,
                          "relativeTimeSelected",
                          $event
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "CRow",
        [
          _c("CCol", [
            _c("span", { staticClass: "time-label" }, [
              _c("h6", [_vm._v("Showing the Last " + _vm._s(_vm.timeRange))])
            ])
          ]),
          _c("CCol", [
            _c(
              "span",
              { staticClass: "float-right text-middle" },
              [
                _c("CInputCheckbox", {
                  attrs: {
                    shape: "pill",
                    variant: "outline",
                    color: "info",
                    type: "checkbox",
                    label: "Hide Offline Devices",
                    checked: _vm.filter.showAll
                  },
                  on: {
                    "update:checked": function($event) {
                      return _vm.$set(_vm.filter, "showAll", $event)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _c("h6", [_vm._v(" Only Show Online & Alarming ")])
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            [
              _c("CDataTable", {
                staticClass: "my-2 py-0 truck-status-table",
                attrs: {
                  fields: _vm.fields,
                  items: _vm.filteredStatus,
                  "table-filter": false,
                  "table-filter-value": _vm.searchValue,
                  hover: "",
                  "items-per-page": _vm.small ? 20 : 50,
                  pagination: "",
                  dense: "",
                  "row-clicked": _vm.handleRowClicked,
                  responsive: "",
                  noItemsView: {
                    noResults: "No results Available",
                    noItems: "No Devices are Online or Alarming"
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "id",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "status",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "name",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "device",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  },
                  {
                    key: "details",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "div",
                          { staticClass: "truck-status-container rounded" },
                          [
                            _c("TruckStatusCard", {
                              key: _vm.statusUpdate,
                              staticClass: "p-0 m-0",
                              attrs: { item: item, points: item.points },
                              on: {
                                click: function($event) {
                                  return _vm.handleRowClicked(item, item.id)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }