

<template>
  <div>
    <strong><span class="label">{{ label }} </span></strong>
    
  </div>
</template>


<script>

import CFormInputBase from "./CFormInputBase";  
export default {
  name: "CLabel",
  extends:CFormInputBase,
  components:{},
  props: {
      /*
    label: String,
    isValid: {
      type: Boolean,
      default: undefined,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredMessage: {
      type: String,
      default: "*",
    },
    tooltip: { type: String | Object },
    */
  },
  data() {
    return {};
  },
  
};
</script>
<style scoped>
.label{
  word-wrap: normal;
}
</style>